<template>
  <div class="investigation">
    <van-form @submit="onSubmit">
    <div v-if="tip" style="background: #fff;padding: 1.4rem;text-align: center;font-size: 18px;color: red;">
      {{tip}}
    </div>
    <div class="content" v-if="!tip">
      <div id="toptitle">
        <h1 class="htitle" id="htitle">{{viewData.name}}</h1>
      </div>
      <div id="divDesc" class="formfield">  
        <span class="description">
          {{viewData.remark}}
        </span>
      </div>
      <div id="divQuestion">
        <template v-for="(item,idx) in viewData.question">
          <div class="field-label">{{idx+1}}. {{item.name}}<span class="req">*</span></div>

          <div class="ui-controlgroup" v-if="item.ti_type == '1'">
            <van-field name="radio" style="padding:0;" :rules="[{ required: true, message: '请正确填写' }]">
              <template #input>
                <van-radio-group v-model="item.answer" style="width: 100%;">
                  <div class="item" v-for="itemTwo in item.options_json">
                    <van-radio :name="itemTwo.index">{{itemTwo.content}}</van-radio>
                  </div>
                </van-radio-group>
              </template>
            </van-field>
          </div>

          <div class="ui-controlgroup" v-if="item.ti_type == '2'">
            <van-field name="checkboxGroup" style="padding:0;" :rules="[{ required: true, message: '请正确填写' }]">
              <template #input>
                <van-checkbox-group v-model="item.answer" style="width: 100%;">
                  <div class="item" v-for="itemTwo in item.options_json">
                    <van-checkbox :name="itemTwo.index" shape="square">{{itemTwo.content}}</van-checkbox>
                  </div>
                </van-checkbox-group>
              </template>
            </van-field>
          </div>

          <div class="ui-controlgroup" style="border: 1px solid #e3e3e3;" v-if="item.ti_type == '3'">
            <van-field v-if="item.placeholder" v-model.number="item.answer" maxlength="11" type="digit" :rules="[{ required: true, message: '请正确填写' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}]" :placeholder="item.placeholder || '请输入内容'" />
            <van-field type="textarea" autosize v-else v-model.number="item.answer" :rules="[{ required: true, message: '请正确填写' }]" placeholder="请输入内容" />
          </div>
        </template>
      </div>

      <!-- <van-progress pivot-text="" inactive :percentage="50" /> -->

      <div id="divSubmit">
        <van-button class="submitbtn mainBgColor" id="ctlNext" :class="[submitLoad ? 'eventNone' : '']" round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </div>
  </van-form>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { Notify } from 'vant';
// 主要通知
// Notify({ type: 'primary', message: '通知内容' });
// 成功通知
// Notify({ type: 'success', message: '通知内容' });
// 危险通知
// Notify({ type: 'danger', message: '通知内容' });
// 警告通知
// Notify({ type: 'warning', message: '通知内容' });
import Cookies from 'js-cookie'
import utils from '@/api/Utils'
export default {
  data() {
    return {
      tip:'',
      loadShow:false,
      submitLoad:false,
      params: {
        cate_id: "",
        page: 1,
        size: 10
      },
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode?url=worldlList",
      memberUrl: "http://speaker.shanmuzhi.com/#/worldlList",
      viewData:[],
    };
  },
  props:[],
  components: {
  },
  mounted() {
  },
  created() {
    let that = this
    // 分享
    that.getCodeUrl = that.$publicData.domain + "api/wxcode?url=worldlList";
    that.memberUrl = that.$publicData.domain + "#/worldlList";
    this.loadShow = true
    this.getViewData()
  },
  watch: {
  },
  methods: {
    getViewData(){
      let that = this
      let ps ={
        uid: this.$route.query.uid
        // uid: this.$route.query.uid || '63267e5ddbfe2'
      }
      that.$api.orther.questionnaireList(ps).then(function(response) {
        that.loadShow = false
        if (response.code === 200) {
          if(response.result.is_del ==1){
            that.tip = '问卷调研已关闭'
            return
          }
          if(response.result.status ==0){
            that.tip = '问卷未发布'
            return
          }
          if(response.result.status ==2){
            that.tip = '问卷调研已结束'
            return
          }
          let obj = {
            "ti_type": 3,
            "name": "您的手机号",
            placeholder: "请填写手机号，提交成功后可获取"+ response.result.score +'积分'
          }
          response.result.question.push(obj)
          that.viewData = response.result
          that.shareFun()
        }
      })
    },
    onSubmit(){
      let that = this
      let ps ={
        questionnaire_id:this.viewData.id,
        phone:'',
        answer_json:[]
      }
      // console.log(this.viewData.question)
      this.submitLoad = true
      let arr = []
      this.viewData.question.forEach((item,idx) => {
        if(!item.questionnaire_id){
          ps.phone = item.answer
          return
        }
        let obj ={}
        if(item.ti_type == 1){
          // console.log(item)
          obj = {
            question_id:item.id,
            answer:[item.answer],
          }
        } else if(item.ti_type == 2){
          obj = {
            question_id:item.id,
            answer:item.answer,
          }
        } else if(item.ti_type == 3){
          obj = {
            question_id:item.id,
            answer:[item.answer],
          }
        }
        arr.push(obj)
      });
      ps.answer_json=arr
      console.log(ps)
      that.$api.orther.questionSubmit(ps).then(function(response) {
        that.submitLoad = false
        if (response.code === 200) {
          Dialog.alert({
            title: '',
            message:
              '提交成功',
          })
            .then(() => {
              // on confirm
              window.location.reload()
            })
        }
      })
    },
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    shareFun() {
      var that = this;
      var tempurl = location.href;
      const url = location.href.split("#")[0];
      const shareUrl = tempurl;
      console.log("Iframe ---share----url=" + shareUrl);
      that.$api.index.getShare({ url: url }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // console.log('Iframe ---微信分享设置---开始');
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.viewData.name, // 分享标题
              desc: that.viewData.remark, // 分享描述
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              title: that.viewData.name, // 分享标题
              link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
            // console.log('Iframe ---微信分享设置---结束');
          });
        }
      });
    },
    timestampToTime (timestamp, type) {
      if (!timestamp) return
      return utils.timestampToTime(timestamp, type)
    },
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    },
  }
};
</script>

<style>
  .eventNone{
    pointer-events: none;
  }
.investigation{
  width: 100%;
  min-height: 100vh;
  background-color: #eaf2f7;
  background-image: url(./img/defaultpcbg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 1.2rem;
}
.investigation .content{
  width: 10rem;
  margin: 0 auto;
  background: #FFF;
}
#toptitle {
    padding: 0.6rem 1.7rem 0.267rem;
    background: #fff;
}
#toptitle .htitle {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    /* font-size: 0.56rem;
    line-height: 0.53rem; */
    font-size: 24px;
    line-height: 30px;
    color: #0095ff;
    text-align: center;
}
div.formfield {
    display: block;
    margin: 0 0.4rem;
    padding-bottom: 0.4rem;
    padding-top: 0.2rem;
    clear: both;
    border-bottom: 1px dashed #ccc;
}
span.description {
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
    display: inline-block;
}
#divQuestion{
  padding: 0.4rem;
  padding-top: 0;
}
#divQuestion .field-label{
  position: relative;
    font-size: 17px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    padding: 0 0 10px 0px;
    display: block;
    word-wrap: break-word;
    line-height: 26px;
    color: #262626;
  margin-top: 40px;
  margin-left: 14px;
}
#divQuestion .field-label span.req {
    position: absolute;
    top: 0;
    left: -13px;
    color: #ff4040;
    margin: 2px 0 0 2px;
}
.ui-controlgroup {
    padding: 0;
    border: 1px solid #e3e3e3;
    border-bottom: 0;
}
.ui-controlgroup .item{
  padding: 15px;
  border-bottom: 1px solid #e3e3e3;
}
#divSubmit{
  padding: 10px 20px;
  margin-top: 20px;
  padding-bottom: 1rem;
}
#ctlNext {
    width: 100%;
    display: block;
    margin: 0 auto;
    outline: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    font-weight: 500;
    border-radius: 2px;
    color: #fff;
    background: #0095ff;
}
</style>